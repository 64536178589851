import React from "react";
import Form from "@components/form";
import PropTypes from "prop-types";
import formFields from "../../../../../../helpers/form-fields";
import { useState } from "react";

const positiveIntegerRegex = /^[1-9]\d*$/;
const fakeRegex = /^[a-zA-Z]+$/;

const Options = (props) => {
  const [minutes, setMinutes] = useState(props.event?.settings.time.minutes);
  const [points, setPoints] = useState(props.event?.settings.time.points);
  const [minMinutes, setMinMinutes] = useState(
    props.event?.settings.time.minMinutes
  );
  const [minPoints, setMinPoints] = useState(
    props.event?.settings.time.minPoints
  );
  const [proportional, setProportional] = useState(
    props.event?.settings.time.proportional
  );
  const [timeManagement, setTimeManagement] = useState(
    props.event?.timeManagement
  );

  return (
    <div className="form-container tab-content">
      <Form
        componentName="events"
        formName="options"
        value={props.event}
        onSubmit={(data) => {
          if (Object.keys(data.errors).length > 0) return;
          props.updateEvent(props.event.id, data.value);
        }}
        onCancel={() => {}}
        onChange={({ key, value }) => {
          // We get the old and new values of time management to test the control
          const oldTimeManagement = timeManagement;
          const newTimeManagement =
            key === "timeManagement" ? Number(value) : oldTimeManagement;

          // If switch is closed and didn't change in onChange the control check don't run

          if (
            (key !== "timeManagement" && oldTimeManagement === 0) ||
            (key === "timeManagement" && newTimeManagement === 0)
          ) {
            setTimeManagement(newTimeManagement);
            return;
          }
          setTimeManagement(newTimeManagement);
          // Because the name can come either from switch either from input we get the name and value from different places(consts)

          const keyName = key.target ? key.target.name : key;
          const keyValue = key.target ? key.target.value : value;

          // We check if the proportional switch is open or opened now

          const oldProportional = proportional;
          const newProportional =
            key === "proportional" ? Number(value) : oldProportional;

          if (
            (key !== "proportional" && oldProportional === 0) ||
            (key === "proportional" && newProportional === 0)
          ) {
            setProportional(newProportional);
            return;
          }

          setProportional(newProportional);

          // We check if minutes are greater than minMinutes
          // We check if points are greater than minPoints

          // Here we should add a regexPattern in minMinutes if it's lower than minutes and greater than 0
          // and another regexPattern in minPoints who minPoints will be lower than points and greater than 0

          const newMinutes = keyName === "minutes" ? keyValue : minutes;
          const newPoints = keyName === "points" ? keyValue : points;
          const newMinMinutes =
            keyName === "minMinutes" ? keyValue : minMinutes;
          const newMinPoints = keyName === "minPoints" ? keyValue : minPoints;

          // Set validation pattern based on conditions
          if (Number(newMinPoints) >= Number(newPoints)) {
            formFields.forms.events.options.minPoints.regexPattern = fakeRegex;
          } else {
            formFields.forms.events.options.minPoints.regexPattern =
              positiveIntegerRegex;
          }

          if (Number(newMinMinutes) >= Number(newMinutes)) {
            formFields.forms.events.options.minMinutes.regexPattern = fakeRegex;
          } else {
            formFields.forms.events.options.minMinutes.regexPattern =
              positiveIntegerRegex;
          }

          // Update each state based on key
          if (keyName === "points") {
            setPoints(keyValue);
          } else if (keyName === "minutes") {
            setMinutes(keyValue);
          } else if (keyName === "minPoints") {
            setMinPoints(keyValue);
          } else if (keyName === "minMinutes") {
            setMinMinutes(keyValue);
          }
        }}
      />
    </div>
  );
};

Options.propTypes = {
  event: PropTypes.object.isRequired,
  updateEvent: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  minutes: PropTypes.number.isRequired,
  points: PropTypes.number.isRequired,
  proportional: PropTypes.bool.isRequired,
  minMinutes: PropTypes.number.isRequired,
  minPoints: PropTypes.number.isRequired,
};

export default Options;

/*  Steps to implement validation function:
// 1) Get all the values
// 2) First step is to check if "timeManagement" is 1(true)
// 3) We check if minutes and points are greater than 0
// 4) We check if proportional switch is open.
// 5) And then we must check if minutes are greater than minMinutes and points greater than minPoints
*/
