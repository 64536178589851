import React from "react";
import PropTypes from "prop-types";
import Select2 from "@layout/Select2";

const AbstractInstitutes = (props) => {
  const [state, setState] = React.useState({ authors: [] });
  const [errors, setErrors] = React.useState({});

  const getAuthors = () => {
    const authors = JSON.parse(JSON.stringify(props.authors));

    const lastAuthor = authors[authors.length - 1] || {};
    if (
      (lastAuthor.firstName != "" ||
        lastAuthor.lastName != "" ||
        lastAuthor.email != "" ||
        lastAuthor.institutes.length) &&
      !props.disabled
    )
      authors.push({
        firstName: "",
        lastName: "",
        email: "",
        institutes: [],
      });

    return authors;
  };

  React.useEffect(() => {
    const authors = getAuthors();
    setState({ authors });
    validateAuthors(authors);
  }, [props]);

  const validateAuthors = (authors) => {
    const newErrors = {};
    authors.forEach((author, index) => {
      if (!author.firstName || author.firstName.trim() === "") {
        newErrors[`author_${index}_firstName`] = "This field is required.";
      }
      if (!author.lastName || author.lastName.trim() === "") {
        newErrors[`author_${index}_lastName`] = "This field is required.";
      }
    });
    setErrors(newErrors);
  };

  const onChangeAuthor = (prop, value, i) => {
    const newAuthors = [...state.authors];
    newAuthors[i][prop] = value;

    const newErrors = { ...errors };
    if (prop === "firstName" && newErrors[`author_${i}_firstName`]) {
      delete newErrors[`author_${i}_firstName`];
    } else if (prop === "lastName" && newErrors[`author_${i}_lastName`]) {
      delete newErrors[`author_${i}_lastName`];
    }
    setErrors(newErrors);
    setState({ authors: newAuthors });
    return props.onChange(newAuthors);
  };
  const removeAuthor = (i) => {
    const newAuthors = [...state.authors];
    newAuthors.splice(i, 1);
    setState({ authors: newAuthors });
    validateAuthors(newAuthors);
    return props.onChange(newAuthors);
  };
  const onChangeInstitute = (value, i) => {
    const newAuthors = [...state.authors];
    newAuthors[i].institutes = value;
    return props.onChange(newAuthors);
  };
  return (
    <div className="authors-container">
      <div className="row author-header">
        <div className="col-sm-3 col-xs-12 ">
          <label>First Name (*)</label>
        </div>
        <div className="col-sm-3 col-xs-12 form-group">
          <label>Last Name (*)</label>
        </div>
        <div className="col-sm-3 col-xs-12 form-group">
          <label>Email</label>
        </div>
        <div className="col-sm-3 col-xs-12 form-group">
          <label>Institute</label>
        </div>
      </div>
      {state.authors.map((author, index) => (
        <div key={`author-${index}`} className="row author-item ">
          <div
            className={`col-sm-3 col-xs-12 form-group ${
              errors[`author_${index}_firstName`] ? "has-error" : ""
            }`}
          >
            <input
              disabled={props.disabled}
              className="form-control has-error"
              value={author.firstName}
              placeholder="First Name"
              onChange={(e) =>
                onChangeAuthor("firstName", e.target.value, index)
              }
            />
            {errors[`author_${index}_firstName`] && (
              <div className="help-block">
                {errors[`author_${index}_firstName`]}
              </div>
            )}
          </div>
          <div
            className={`col-sm-3 col-xs-12 form-group ${
              errors[`author_${index}_lastName`] ? "has-error" : ""
            }`}
          >
            <input
              disabled={props.disabled}
              className="form-control has-error"
              value={author.lastName}
              placeholder="Last Name"
              onChange={(e) =>
                onChangeAuthor("lastName", e.target.value, index)
              }
            />
            {errors[`author_${index}_lastName`] && (
              <div className="help-block">
                {errors[`author_${index}_lastName`]}
              </div>
            )}
          </div>
          <div className="col-sm-3 col-xs-12 form-group">
            <input
              disabled={props.disabled}
              className="form-control"
              value={author.email}
              placeholder="Email"
              onChange={(e) => onChangeAuthor("email", e.target.value, index)}
            />
          </div>
          <div className="col-sm-3 col-xs-12 form-group author-institute-select">
            <Select2
              className={props.disabled ? "" : "select-institute"}
              minHeight={34}
              disabled={props.disabled}
              options={props.abstract.institutes.reduce(
                (prev, next) => ({ ...prev, [next.index]: next.name }),
                {}
              )}
              onChange={(val) => {
                onChangeInstitute(val, index);
              }}
              placeholder={"Select one or more institute"}
              value={author.institutes}
              multi={true}
              isSearchable={false}
              isClearable={false}
            />
            {!props.disabled && index != state.authors.length - 1 && (
              <div className="remove-item">
                <span
                  onClick={() => removeAuthor(index)}
                  className="fa fa-times-circle"
                ></span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

AbstractInstitutes.defaultProps = {
  authors: [],
};
AbstractInstitutes.propTypes = {
  authors: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  abstract: PropTypes.object.isRequired,
};

export default AbstractInstitutes;
